































































































































import { ProductMutations, ProductState } from "@/store/modules/product/definitions.product";
import { mapState, mapMutations } from "vuex";
import Vue from "vue";
import { TProductShop } from "@/models/Product";
import RouterMove from "@/mixins/routerMixin";
import { GET_IMAGE_URL } from "@/plugins/constants";
// import product from "@/store/modules/product/product";

export default Vue.extend({
  mixins: [RouterMove],
  name: "shoppingDrawer",
  props: {
    drawer: Boolean,
  },
  data: () => ({
    imgs: [] as Array<string>,
    icons: [
     `${GET_IMAGE_URL(false)}/icons/close.svg`,
     `${GET_IMAGE_URL(false)}/icons/close-circle.svg`,
     `${GET_IMAGE_URL(false)}/icons/minus.svg`,
     `${GET_IMAGE_URL(false)}/icons/plus.svg`,
     `${GET_IMAGE_URL(false)}/icons/shopping-outline.svg`,
    ]
  }),
  created() {
    (this as any).getProductImages();

    /*const w: Function = this.$watch('prodList',() => {
      (this as any).getProductImages();
      w();
    })*/
  },
  watch: {
    prodList() {
      (this as any).getProductImages();
    },
   },
  computed: {
    ...mapState("product", {
      prodList: ProductState.SHOPPING_LIST,
    }),
    isEmpty() {
      return this.prodList.length === 0;
    },
    subTotal() {
      let aux = 0;
      this.prodList.forEach((ele: TProductShop) => {
        aux += (ele.discount_format == "" || ele.discount_format == undefined ? ele.price : ele.discount) * ele.quantity;
      });
      return aux.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    },
  },
  methods: {
    ...mapMutations("product", [ProductMutations.DELETE_PRODUCT]),
    deleteProd(productID: number) {
      this.prodList.splice(productID, 1);
      // console.dir(this[ProductMutations.DELETE_PRODUCT]);

      this[ProductMutations.DELETE_PRODUCT](productID);
    },
    increaseUnits(productID: number) {
      this.prodList[productID].quantity += 1;
    },
    decreaseUnits(productID: number) {
      this.prodList[productID].quantity > 1 && (this.prodList[productID].quantity -= 1);
    },
    getProductImages() {
      this.imgs = []
      for (let index = 0; index < this.prodList.length; index++) {
        this.imgs[index] = `${GET_IMAGE_URL(true)}/articles/${this.prodList[index].sku_code}/small/0.webp`;
      }
    },
  },
});
