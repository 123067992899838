import * as dotenv from 'dotenv';
dotenv.config()

const DEV = process.env['NODE_ENV'] == 'development'

const SERVER = process.env['NODE_ENV'] == 'development' ? 'http://localhost' : 'https://srvcs-etpconsulting.com.ar';

const SERVER_IMAGE_URL_BASE = `${SERVER}:port`
const PORTS = DEV ? ['8801'] : ['8801', '8802'];

//eslint-disable-next-line
export const E = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;

const generateRandomNumber = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min);
};

export const SERVER_URL = `${SERVER}:8800`

export const GET_IMAGE_URL = function (image: boolean): string {
    const index = DEV ? 0 : (generateRandomNumber(0, 10) > 5 ? 1 : 0);

    return `${SERVER_IMAGE_URL_BASE.replace('port', PORTS[index])}`.concat((image) ? '/images' : '')
}


// export enum URLS {
//     SERVER_URL = 'https://srvcs-etpconsulting.com.ar:8800',
//     SERVER_IMAGE_URL = 'https://srvcs-etpconsulting.com.ar:8801/image',
//     SERVER_IMAGE_URL_BASE = 'https://srvcs-etpconsulting.com.ar:8801'
//     // SERVER_URL = 'http://localhost:8800',
//     // SERVER_IMAGE_URL = 'http://localhost:8801/image',   
//     // SERVER_IMAGE_URL_BASE = 'http://localhost:8801'
// } 


export const _PROVINCES = [
    'BUENOS AIRES',
    'CABA',
    'CATAMARCA',
    'CHACO',
    'CHUBUT',
    'CORDOBA',
    'CORRIENTES',
    'ENTRE RIOS',
    'FORMOSA',
    'JUJUY',
    'LA PAMPA',
    'LA RIOJA',
    'MENDOZA',
    'MISIONES',
    'NEUQUEN',
    'RIO NEGRO',
    'SALTA',
    'SAN JUAN',
    'SAN LUIS',
    'SANTA CRUZ',
    'SANTA FE',
    'SANTIAGO DEL ESTERO',
    'TIERRA DEL FUEGO',
    'TUCUMAN'
]

export const capitalize = (s: string): string => `${s.charAt(0).toUpperCase()}${s.slice(1)}`  