import {
  ProductState,
  ProductMutations,
  ProductActions,
  ProductGetters,
  TProdcutsState,
} from "./definitions.product";
import { GET_IMAGE_URL } from "@/plugins/constants";
import { TProduct, TProductShop } from "@/models/Product";
import { uniq, orderBy, filter, chunk } from 'lodash'
import axios from 'axios';
import { SERVER_URL } from '@/plugins/constants'
import { FilterInterface } from "@/models/FilterInterface";

const state: TProdcutsState = {
  [ProductState.SELECTED_PRODUCT]: "" as string,
  [ProductState.SHOPPING_LIST]: [] as Array<TProductShop>,
  [ProductState.PRODUCTS_LIST]: [] as Array<TProduct>,
};

interface TContext {
  commit: Function;
  state: TProdcutsState;
}


export default {
  namespaced: true,
  state: () => state,
  mutations: {
    [ProductMutations.ADD_PRODUCT](state: TProdcutsState, productShop: TProductShop) {
      state[ProductState.SHOPPING_LIST] = [productShop].concat(state[ProductState.SHOPPING_LIST]);
    },
    [ProductMutations.SET_PRODUCTS_LIST](state: TProdcutsState, items: Array<TProduct>) {
      state[ProductState.PRODUCTS_LIST] = items.map(item => {
        return Object.freeze({
          ...item,
          url: item.title.toLowerCase().replaceAll(" ", "-").normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        })
      })
      state[ProductState.AVAILABLE_MODELS] = uniq(state[ProductState.PRODUCTS_LIST].map(item => item.model))
      state[ProductState.AVAILABLE_CLOTHS] = uniq(state[ProductState.PRODUCTS_LIST].map(item => item.cloths).flat())
    },
    [ProductMutations.CLEAN_SHOPPING_LIST](state: TProdcutsState) {
      state[ProductState.SHOPPING_LIST] = []
    },
    [ProductMutations.DELETE_PRODUCT](state: TProdcutsState, index: number) {
      state[ProductState.PRODUCTS_LIST].splice(index, 1);
    }
  },
  actions: {
    async [ProductActions.API_PRODUCTS_LIST]({ commit }: TContext) {
      return await axios.get(`${SERVER_URL}/articles/active`).then((res) => commit(ProductMutations.SET_PRODUCTS_LIST, res.data.data));
    },
  },
  getters: {
    [ProductGetters.GET_CONDITIONAL_PRODUCTS]: (state: TProdcutsState) => (
      filters: FilterInterface,
      count: number,
      limit = false,
      selections = true,
      order = "",
    ) => {
      const products = state[ProductState.PRODUCTS_LIST]

      if (products.length == 0) {
        return []
      }

      const { collections, model, cloths, sizes } = filters

      // Filters - news, discuount, spring, atom
      const universe = filter(products, function (item: TProduct) {
        if (!collections && !model && !cloths && !sizes) {
          return true
        }

        // Collections
        if (collections && collections.length) {
          for (let i = 0; i < item.collections.length; i++) {
            const col = item.collections[i];

            if (col && collections?.indexOf(col) != -1) {
              return true
            }
          }
        }

        // Cloth
        if (cloths && cloths.length) {
          for (let c = 0; c < item.cloths.length; c++) {
            const cloth = item.cloths[c];
            if (cloth && cloths?.indexOf(cloth) != -1) {
              return true
            }
          }
        }

        // Model
        if (model && model.length) {
          // Model
          if (model && model?.indexOf(item.model) != -1) {
            return true
          }
        }

        // Sizes
        if (sizes && sizes.length) {
          for (let i = 0; i < sizes.length; i++) {
            const size = sizes[i];
            if (sizes?.indexOf(size) != -1) {
              return true
            }
          }
        }

        return false
      }).map((item: TProduct) => Object.freeze(item))

      // If limit is true, only diplay finite elements. Otherwise its for filter page
      if (!limit) {
        //slit array on page => [{},{},...] one obj = one page
        let finalUniverse = universe

        // (rows: any, key: number, index: number) =>
        //     (index % count == 0
        //       ? rows.push([key])
        //       : rows[rows.length - 1].push(key)) && rows,
        //   []

        if (order) {
          // FIXME: Esto me parece que no sirve
          if (order == "Novedad") {
            // finalUniverse = orderBy(finalUniverse, ['updated_at'], ['desc'])
          } else {
            finalUniverse = orderBy(finalUniverse, ['price'], [(order == "De menor a mayor") ? 'asc' : 'desc'])
          }
        }

        const finalUniverse2 = chunk(finalUniverse, count)

        if (selections) {
          // console.log("pepe",universe);
          //Selection Availables

          const availableModels = uniq(universe.map((item: TProduct) => item.model));
          const availableCloths = uniq(universe.map((item: TProduct) => item.cloths).flat())
          const availableCollections = uniq(universe.map((item: TProduct) => item.collections).flat())

          return {
            universe: finalUniverse2,
            availableCloths,
            availableModels,
            availableCollections
          }
        } else {
          return finalUniverse2
        }
      } else {
        // return orderBy(universe, ['UpdateAt'], ['desc']).slice(0, count)
        return universe.slice(0, count)
      }
    },
  }
};
