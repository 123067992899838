






















































































































































import Vue from "vue";
import { State } from "@/store/definitions";
import { mapState,mapMutations } from "vuex";
import axios from "axios";
import { TTextTitleHome } from "@/models/User";
import { FilterMutations, TFilterState } from "@/store/modules/filter/definitions.filter";
import { capitalize } from '@/plugins/constants'
import { GET_IMAGE_URL, SERVER_URL } from "@/plugins/constants";

export default Vue.extend({
  name: "Home",
  metaInfo: {
    title: "ThreeUnder",
  },
  components: {
    cardsService: () => import(/* webpackChunkName: "cardsService" */ "@/components/product/cardsService.vue"),
  },
  data: () => ({
    loading: false,
    carouselHome: 0,
    carouselHeight: 800,
    carouselObj: [{}] as Array<TTextTitleHome>,
    bannerObj: [{}] as Array<TTextTitleHome>,
    carouselImages: []  as Array<string>,
    bannerImages: [] as Array<string>,
    imgs: `${GET_IMAGE_URL(false)}/assets/3u_isologo_green.png`,
    icons:[`${GET_IMAGE_URL(false)}/icons/chevron-up-white.svg`],
    sizeScreen: "",
  }),
  created() {
    const desktop = this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    this.sizeScreen = desktop ? "desktop" : this.$vuetify.breakpoint.sm ? "tablet" : "mobile";

    if(this.sizeScreen == "mobile"){
      this.carouselHeight = 600
    }

    this.carouselImages = [
      `${GET_IMAGE_URL(true)}/carousel/${this.sizeScreen}/0.webp`,
      `${GET_IMAGE_URL(true)}/carousel/${this.sizeScreen}/1.webp`,
      `${GET_IMAGE_URL(true)}/carousel/${this.sizeScreen}/2.webp`
    ]
    this.bannerImages = [
      `${GET_IMAGE_URL(true)}/banner/0.webp`,
      `${GET_IMAGE_URL(true)}/banner/1.webp`,
      `${GET_IMAGE_URL(true)}/banner/2.webp`
    ]
    // Title and text for carousel and banner
    axios.get(`${SERVER_URL}/texts/`).then((res) => {
      const { carousel, banner } = res.data.data[0];
      this.carouselObj = carousel.filter((item: any) => item.title != "");
      this.bannerObj = banner;
      this.loading = true;
    });
  },
  methods: {
    ...mapMutations('filter', [FilterMutations.SET_FILTER_STATE]),    
    pushProducts(params: string) {
      const final_collection = capitalize(params.toLowerCase())
      this[FilterMutations.SET_FILTER_STATE]({
        currentPage: 1,
        selectedOrderBy: "Novedad",
        selectedCollection: final_collection
      } as TFilterState);
      this.$router.push('/shop/collection');
    },
    searchTag(text: string) {
      this.$router.push({ name: "SearchPage", params: { text: text } });
    },
  },
  computed: {
    ...mapState({
      tags: State.TAGS,
    }),
  },
});
