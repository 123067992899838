import { Component, Vue } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";


@Component
class RouterMove extends Vue {
    public routerMove(path: string, params?: Dictionary<string>) {
        if (!params) {
        this.$router.push({ name: path });
        } else {
        this.$router.push({ name: path,  params });
        }
    }
}

export default RouterMove