
import Vue from 'vue'
import Vuex from 'vuex'

import filterModule from './modules/filter/filter'
import productModule from './modules/product/product'

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

import { TUser, TShipping } from '@/models/User'
import { State, Mutations, Actions } from './definitions'
import axios from 'axios';
import { SERVER_URL } from '@/plugins/constants'
import { TGiftCard } from '@/models/Product';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    filter: filterModule,
    product: productModule
  },
  plugins: [
    //Save vuex state on local storage and keep data even if refresh the page
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ],
  // User and Tags
  state: {
    [State.USER]: { Username: "", JWT: "", Name: "" } as TUser,
    [State.TAGS]: [] as Array<string>,
    [State.SHIPPING]: { Province: "", Cost: 0, PostOffice: "" } as TShipping,
    [State.DISCOUNT]: 0,
    [State.GIFTCARD]: { code: "", discount: 0 } as TGiftCard
  },
  mutations: {
    [Mutations.SET_USER](state, user: TUser) {
      state[State.USER] = { Username: user.Username, JWT: user.JWT, Name: user.Name }
    },
    [Mutations.SET_TAGS](state, tags: Array<any>) {
      state[State.TAGS] = tags.map(tag => tag.description)
    },
    [Mutations.SET_SHIPPING](state, shipping: TShipping) {
      state[State.SHIPPING] = shipping
    },
    [Mutations.SET_DISCOUNT](state, discount: number) {
      state[State.DISCOUNT] = discount
    },
    [Mutations.SET_GIFTCARD](state, giftCard: TGiftCard) {
      state[State.GIFTCARD] = giftCard
    },
  },
  actions: {
    async [Actions.API_TAGS]({ commit }) {
      return await axios.get(`${SERVER_URL}/tags/`).then(
        (res) => commit(Mutations.SET_TAGS, res.data.data)
      )
    }
  }
})
