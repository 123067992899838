import { FilterState, FilterMutations, TFilterState } from "./definitions.filter";

const state: TFilterState = {
  [FilterState.SELECTED_MODELS]: [] as Array<string>,
  [FilterState.SELECTED_CLOTHS]: [] as Array<string>,
  [FilterState.SELECTED_COLLECTION]: "" as string,
  [FilterState.SELECTED_ORDER_BY]: "Novedad" as string,
  [FilterState.CURRENT_PAGE]: 1 as number,
};

export default {
  namespaced: true,
  state: () => state,
  mutations: {
    [FilterMutations.SET_FILTER_STATE](state: TFilterState, payload: TFilterState) {
      let {
        selectedCloths,
        selectedModels,        
      } = payload;     
      
      const {
        selectedCollection,
        selectedOrderBy,
        currentPage
      } = payload;
      
      (selectedCloths && typeof(selectedCloths) == "string") && (selectedCloths = [selectedCloths]);
      (selectedModels && typeof(selectedModels) == "string") && (selectedModels = [selectedModels]);

      state[FilterState.SELECTED_CLOTHS] = selectedCloths || [] as Array<string>
      state[FilterState.SELECTED_MODELS] = selectedModels || [] as Array<string>
      state[FilterState.SELECTED_COLLECTION] = selectedCollection || '' as string
      state[FilterState.SELECTED_ORDER_BY] = selectedOrderBy || 'Novedad' as string
      state[FilterState.CURRENT_PAGE] = currentPage || 1 as number 
    },
    [FilterMutations.SET_CURRENT_PAGE](state: TFilterState, item: number) {
      state[FilterState.CURRENT_PAGE] = item;
    },
  },
  actions: {},
  getters: {},
};
