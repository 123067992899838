











































































































































































































































import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import sideBarMobile from "@/components/headerBar/sideBarMobile.vue";
import sideBarShop from "@/components/headerBar/sideBarShop.vue";
import { ProductState } from "@/store/modules/product/definitions.product";
import buttonsBar from "@/components/headerBar/buttonsBar.vue";
import { State, Mutations } from "@/store/definitions";
import RouterMove from "@/mixins/routerMixin";
import { GET_IMAGE_URL } from "@/plugins/constants";

export default Vue.extend({
  name: "bar",
  mixins: [RouterMove],
  components: { sideBarMobile, sideBarShop, buttonsBar },
  data: () => ({
    icons: [] as Array<string>,
    searchFlag: false,
    menu: false,
    drawerBarShop: false,
    dialogLogOut: false,
    drawer: false,
    refresh: true,
    imgs: [] as Array<string>,
    find: "",
  }),
  created() {
    window.addEventListener("load", () => {
      this.icons = [
        `${GET_IMAGE_URL(false)}/icons/magnify.svg`,
        `${GET_IMAGE_URL(false)}/icons/close.svg`,
        `${GET_IMAGE_URL(false)}/icons/account-outline.svg`,
        `${GET_IMAGE_URL(false)}/icons/pencil-outline.svg`,
        `${GET_IMAGE_URL(false)}/icons/history.svg`,
        `${GET_IMAGE_URL(false)}/icons/logout-variant.svg`,
        `${GET_IMAGE_URL(false)}/icons/shopping-outline.svg`,
        `${GET_IMAGE_URL(false)}/icons/menu.svg`,
      ];

      this.imgs = [
        `${GET_IMAGE_URL(false)}/assets/3u_black_simple.webp`,
        `${GET_IMAGE_URL(false)}/assets/3u_isologo_black.png`,
      ];
    });
  },
  mounted() {
    const w: Function = this.$watch("productAvailable", () => {
      this.refresh = false;
      setTimeout(() => {
        this.refresh = true;
      }, 1);
      w();
    });
  },
  // watch:{
  //   productAvailable(){
  //     this.refresh = false
  //     setTimeout(() => {
  //       this.refresh = true
  //     }, 1);
  //   }
  // },
  computed: {
    ...mapState("product", {
      prodList: ProductState.SHOPPING_LIST,
      productAvailable: ProductState.PRODUCTS_LIST,
    }),
    ...mapState({ user: State.USER, tags: State.TAGS }),
  },
  methods: {
    ...mapMutations([Mutations.SET_USER]),
    logOut() {
      setTimeout(() => {
        this.dialogLogOut = !this.dialogLogOut;
        this[Mutations.SET_USER]({ Username: "", JWT: "" });
        this.$router.push({ name: "Home" });
      }, 1000);
    },
    focusSearchInput() {
      setTimeout(() => {
        const theElement = this.$refs.inputSearch as Vue;
        const input = theElement.$el.querySelector("input:not([type=hidden]),textarea:not([type=hidden])") as HTMLElement;
        if (input) {
          setTimeout(() => {
            input.focus();
          }, 0);
        }
      }, 100);
    },
    search() {
      if (this.find) {
        this.searchFlag = false;
        const string = this.find.toLowerCase().replaceAll(" ", "-");
        this.find = "";
        this.$router.push({ name: "SearchPage", params: { text: string } });
      }
    },
    searchOnEnter(e: KeyboardEvent) {
      if (e.key.toLowerCase() === "enter" || e.key.toLowerCase() === "numpadenter") {
        this.search();
      }
    },
    searchTag(text: string) {
      this.searchFlag = false;
      this.$router.push({ name: "SearchPage", params: { text: text } });
    },
    openShoppingCart() {
      if (this.$router.currentRoute.name == "FinalizePurchase") {
        return;
      }

      this.drawerBarShop = !this.drawerBarShop;
    },
  },
});
