






























































































import Vue from "vue";
import RouterMove from "@/mixins/routerMixin";
import { mapMutations, mapState } from "vuex";
import {
  FilterMutations,
  TParams,
  filterOptionsFn,
} from "@/store/modules/filter/definitions.filter";
import { ProductState } from "@/store/modules/product/definitions.product";
import { GET_IMAGE_URL } from "@/plugins/constants";

export default Vue.extend({
  name: "barDrawer",
  props: {
    drawer: Boolean,
  },
  data: () => ({
    icon: `${GET_IMAGE_URL(false)}/icons/close.svg`,
  }),
  mixins: [RouterMove],
  mounted() {
    const w: Function = this.$watch("productsList", () => {
      this.$forceUpdate();
      w();
    });
  },
  methods: {
    ...mapMutations("filter", [FilterMutations.SET_FILTER_STATE]),
    pushProducts(params: TParams) {
      filterOptionsFn(
        params,
        this[FilterMutations.SET_FILTER_STATE],
        (type: string, value: string) => {
          this.$router.push(`/shop/${type}/${value}`);
          console.log(
            this.$router.push(
              type == "collection" ? `/shop/${type}` : `/shop/${type}/${value}`
            )
          );
        }
      );
    },
  },
  /*watch: {
    productsList() {
      this.$forceUpdate()
    },
  },*/
  computed: {
    ...mapState("product", [ProductState.PRODUCTS_LIST]),
    ...mapState("product", {
      availableCloths: ProductState.AVAILABLE_CLOTHS,
      availableModels: ProductState.AVAILABLE_MODELS,
      productsList: ProductState.PRODUCTS_LIST,
    }),
  },
});
