export enum ProductState {
    SELECTED_PRODUCT = 'selectedProduct',
    SHOPPING_LIST = 'shoppingList',
    PRODUCTS_LIST = 'productsList',
    AVAILABLE_CLOTHS = 'availableCloths',
    AVAILABLE_MODELS = 'availableModels'
} 

export enum ProductMutations {
    ADD_PRODUCT = 'addProduct',
    SET_PRODUCTS_LIST = 'setProductList',
    CLEAN_SHOPPING_LIST = 'cleanProductsList',
    DELETE_PRODUCT = 'deleteProduct'
} 

export enum ProductActions{
    API_PRODUCTS_LIST = 'apiProductsList'
}
export enum ProductGetters{
    GET_CONDITIONAL_PRODUCTS = 'getConditionalProducts',
}

//Interface
import {TProductShop,TProduct} from '@/models/Product'
export interface TProdcutsState {
    [ProductState.SELECTED_PRODUCT]: string;
    [ProductState.SHOPPING_LIST]: Array<TProductShop>;
    [ProductState.PRODUCTS_LIST]: Array<TProduct>;
}