import { capitalize } from '@/plugins/constants'

export enum FilterState {
    SELECTED_CLOTHS = 'selectedCloths',
    SELECTED_MODELS = 'selectedModels',
    SELECTED_COLLECTION = 'selectedCollection',
    SELECTED_ORDER_BY = 'selectedOrderBy',
    CURRENT_PAGE = 'currentPage'
}

export enum FilterMutations {
    SET_FILTER_STATE = 'setFilterState',
    SET_CURRENT_PAGE = 'setCurrentPage'
}

export interface TFilterState {
    [FilterState.SELECTED_MODELS]?: Array<string>;
    [FilterState.SELECTED_CLOTHS]?: Array<string>;
    [FilterState.SELECTED_COLLECTION]?: string;
    [FilterState.SELECTED_ORDER_BY]?: string;
    [FilterState.CURRENT_PAGE]?: number;
}

export type TParams = {
    collection: string;
    cloth: Array<string> | string;
    model: Array<string> | string;
}

export const filterOptionsFn = ({ collection, cloth, model }: TParams, mutation: Function, callback: Function): void => {    
    const type = collection ? "collection" : cloth ? "cloths" : "models";

    const index = `selected${capitalize(type)}`;

    const c = (typeof(cloth) == "string" || !cloth) ? cloth : cloth[0]
    const m = (typeof(model) == "string" || !model) ? model : model[0]

    const val = collection || c || m

    mutation({
        currentPage: 1,
        selectedOrderBy: "Novedad",
        [index]: val,
        selectedCollection: collection || "Todas"
    } as TFilterState);

    // console.dir(val.toLowerCase());

    // callback(type, collection ? collection.toLowerCase() : cloth ? cloth[0].toLowerCase() : model[0].toLowerCase())
    callback(type, val.toLowerCase())
}