import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/description/:productUrl',
    name: 'DescriptionProduct',
    props: true,
    component: () => import(/* webpackChunkName: "descriptionProduct" */ '../views/DescriptionProduct.vue')
  },
  {
    path: '/shop/collection',
    name: 'ShopCollections',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue')
  },
  {
    path: '/shop/models/:model',
    name: 'ShopModels',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue')
  },
  {
    path: '/shop/cloths/:cloth',
    name: 'ShopCloths',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue')
  },
  {
    path: '/search/:text',
    name: 'SearchPage',
    props: true,
    component: () => import(/* webpackChunkName: "searchPage" */ '../views/SearchPage.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactPage.vue')
  },
  {
    path: '/register/:purchase',
    name: 'Register',
    props: true,
    component: () => import(/* webpackChunkName: "registerAndLogIn" */ '../views/Register.vue')
  },
  {
    path: '/login',
    name: 'LogIn',
    component: () => import(/* webpackChunkName: "registerAndLogIn" */ '../views/LogIn.vue')
  },
  {
    path: '/purchase-summary',
    name: 'PurchaseSummary',
    component: () => import(/* webpackChunkName: "finalizePurchase" */ '../views/PurchaseSummary.vue')
  },
  {
    path: '/finalize-purchase/:takeaway',
    name: 'FinalizePurchase',
    props: true,
    component: () => import(/* webpackChunkName: "finalizePurchase" */ '../views/FinalizePurchase.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "termsAndConditions" */ '../views/TermsAndConditions.vue')
  },
  {
    path: '/frequent-questions',
    name: 'FrequentQuestions',
    component: () => import(/* webpackChunkName: "frequent" */ '../views/FrequentQuestions.vue')
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "extraPages" */ '../views/AboutUs.vue')
  },
  {
    path: '/cloths-care',
    name: 'ClothsCare',
    component: () => import(/* webpackChunkName: "extraPages" */ '../views/ClothsCare.vue')
  },
  {
    path: '/purchase-regret',
    name: 'PurchaseRegret',
    component: () => import(/* webpackChunkName: "extraPages" */ '../views/PurchaseRegret.vue')
  },
  {
    path: "/user/profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: "user" */ '../views/user/profile.vue')
  },
  {
    path: "/user/history",
    name: "History",
    component: () => import(/* webpackChunkName: "user" */ '../views/user/history.vue')
  },
  {
    path: '/reset-password/:code',
    name: 'ResetPassword',
    props: true,
    component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword.vue')
  },
  {
    path: '/thankyou/:success',
    name: 'ThankYouPage',
    component: () => import(/* webpackChunkName: "finalizePurchase" */ '../views/ThankYouPage.vue'),
    props: true
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "pageNotFound" */ '../views/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior() {
  //   return { x: 0, y: 0 }
  // }
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})

export default router
