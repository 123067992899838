export enum State {
    USER = 'user',
    TAGS = 'tags',
    SHIPPING = 'shipping',
    DISCOUNT = 'discount',
    GIFTCARD = 'giftCard'
}

export enum Mutations {
    SET_USER = 'setUser',
    SET_TAGS = 'setTags',
    SET_SHIPPING = 'setShipping',
    SET_DISCOUNT = 'setDiscount',
    SET_GIFTCARD = 'setGiftCard'
}

export enum Actions {
    API_TAGS = 'apiTags'
}