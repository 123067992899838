



































































import Vue from "vue";
import bar from "@/components/headerBar/bar.vue";
import { GET_IMAGE_URL } from "@/plugins/constants";
import { ProductActions } from "@/store/modules/product/definitions.product";
import { Actions } from "@/store/definitions";
import { mapActions } from "vuex";


export default Vue.extend({
  name: "App",
  metaInfo: {
    title: "ThreeUnder",
    titleTemplate: "%s | 3U Lingerie",
    htmlAttrs: {
      lang: "es-AR",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Shop de lenceria. Mejor calidad. Mejores precios",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  components: {
    bar,
    footerComponet: () => import(/* webpackChunkName: "footer" */ "@/components/footerComponet.vue"),
  },
  data: () => ({
    img: [`${GET_IMAGE_URL(false)}/icons/whatsapp.svg`,`${GET_IMAGE_URL(false)}/icons/chevron-up.svg`],
    error: false,
    flagTop: false,
  }),
  mounted() {
    window.addEventListener("scroll", this.onScroll)
    this[ProductActions.API_PRODUCTS_LIST]()
      .then(
        () => {
          return this[Actions.API_TAGS]();
        },
        () => {
          // this.legend = "Ocurrio un error al cargar el sitio, por favor intente nuevamente más tarde.";
          this.error = true;
        }
      )
      .catch(() => {
        // this.legend = "Ocurrio un error al cargar el sitio, por favor intente nuevamente más tarde.";
        this.error = true;
      });  
      
    window.addEventListener('load',() => {
      const fonts = document.createElement("style");

      fonts.innerHTML = `
        @font-face {
          font-family: "Bebas";
          font-style: normal;
          font-display: swap; /* Read next point */
          unicode-range: U+000-5FF; /* Download only latin glyphs */
          src: url("${GET_IMAGE_URL(false)}/fonts/BebasNeue-Regular.woff2") format("woff2");
        }
      `;

      fonts.id = 'font_bebas'

      document.head.appendChild(fonts);

      const fonts2 = document.createElement("style");

      fonts2.innerHTML = `
        @font-face {
        font-family: "Optima";
        font-style: medium;
        font-display: swap; /* Read next point */
        src: url("${GET_IMAGE_URL(false)}/fonts/Optima-Medium.otf")}
      `;

      fonts2.id = "font_optima"    

      document.head.appendChild(fonts2);  

      const fontIcons = document.createElement("style")  
  
      fontIcons.innerHTML = `
          @font-face {
          font-family: 'Material Icons';
          font-style: normal;
          font-weight: 400;
          font-display: swap; /* Read next point */
          src: url("${GET_IMAGE_URL(false)}/fonts/material_icons.woff2") format("woff2");        
        }
      `    
      fontIcons.id = "fonts_icons"
      document.head.appendChild(fontIcons)
    })
  },
  methods: {
    onScroll(e: any){
      this.flagTop = e.target.documentElement.scrollTop > 100
    },
    goToTheTop(){
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    ...mapActions("product", [ProductActions.API_PRODUCTS_LIST]),
    ...mapActions([Actions.API_TAGS]),
    refresh() {
      window.location.reload();
    },
  },
});
