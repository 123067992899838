






































































































import Vue from "vue";
import RouterMove from "@/mixins/routerMixin";
import { mapMutations, mapState } from "vuex";
import { TFilterState, FilterMutations, TParams, filterOptionsFn } from "@/store/modules/filter/definitions.filter";
import { capitalize } from '@/plugins/constants'
import { ProductState } from "@/store/modules/product/definitions.product";
import { GET_IMAGE_URL } from "@/plugins/constants";

export default Vue.extend({
  mixins: [RouterMove],
  data: () => ({
    hoverCollection: false,
    hoverNews: false,
    hoverSale: false,
    hoverContact: false,
    hoverSets: false,
    hoverSizes: false,
    flagSets: false,
    flagCollection: false,
    imgs: [`${GET_IMAGE_URL(false)}/assets/models.webp`, `${GET_IMAGE_URL(false)}/assets/cloths.webp`],
  }),
  methods: {
    ...mapMutations("filter", [FilterMutations.SET_FILTER_STATE]),
    pushProducts(params: TParams) {
      filterOptionsFn(params, this[FilterMutations.SET_FILTER_STATE], (type: string, value: string) => {
        this.$router.push(`/shop/${type}/${value}`);
      });
    },
    pushProductsCollection(params: string) {
     const final_collection = capitalize(params.toLowerCase())
      this[FilterMutations.SET_FILTER_STATE]({
        currentPage: 1,
        selectedOrderBy: "Novedad",
        selectedCollection: final_collection
      } as TFilterState);
      this.$router.push('/shop/collection');
    }
  },
  computed: {
    ...mapState("product", {
      availableCloths: ProductState.AVAILABLE_CLOTHS,
      availableModels: ProductState.AVAILABLE_MODELS,
    }),
  },
});
