import Vue from 'vue';
// import Vuetify from 'vuetify/lib/framework';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/lib/locale/es.js';

Vue.use(Vuetify);

export default new Vuetify({
  icons:{
    iconfont:'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#3f4d41', //verde
        secondary: '#7d9982', //verde claro
        accent: '#998876', // beige
      },
      dark: {
        primary: '#7d9982',
      }
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
})